.card {
	display: block;
	position: relative;
	border-radius: $border-radius;
	overflow: hidden;
	box-shadow: $box-shadow;

	&--title {
		padding: var(--padding-rythm);
		font-size: var(--fs-body-lg);
	}

	&--read-more {
	}

	transition: transform 200ms ease-in-out;
	&:hover {
		transform: translateY(-4px);
	}
}

@include from($bp-medium) {
	.card {
		&--title {
			padding: var(--padding-rythm-sm);
			font-size: var(--fs-body-lg);
		}
	}
}
