@mixin block-base-sm() {
	background: white;
	padding: var(--padding-rythm-sm);
}

@mixin block-base() {
	background: white;
	padding: var(--padding-rythm);
}

@mixin base-heading() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1.1;
	font-family: $heading-font;
	margin: 0;
	font-weight: 100;
}

@mixin base-link() {
	transition: border-color 150ms ease-in;
	color: inherit;
	position: relative;
	border-bottom: 3px solid white;
	&:hover,
	&:focus,
	&:active {
		border-bottom-color: $dark-grey;
	}
}
