.l-home-fold {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;

	&--tagline {
		font-size: var(--fs-h1);
		font-weight: 800;
		text-transform: uppercase;

		margin: calc(var(--padding-rythm-sm) / 2) 0;
		margin-left: $heading-x-offset;
	}

	.btn-group {
		margin-top: var(--padding-rythm);
	}
}
