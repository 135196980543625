.l-site-footer {
	padding: var(--padding-rythm-sm);
	&--social {
		a {
			display: inline-block;
			text-transform: uppercase;
			+ a {
				margin-left: var(--padding-rythm-sm);
			}
		}
	}
}

@include from($bp-medium) {
	.l-site-footer {
		padding: var(--padding-rythm);
		display: flex;
		justify-content: space-between;
	}
}
