.l-streamfield {
	color: $dark-gold;
	border-radius: $border-radius;
	> * {
		margin-bottom: 0;
		+ * {
			margin-top: var(--padding-rythm-sm);
		}
		+ .section-title {
			margin-top: calc(var(--padding-rythm-sm) * 2);
		}
	}
	> .title {
		margin-bottom: calc(var(--padding-rythm) * 0.5);
	}
	> .btn {
		margin-right: var(--padding-rythm-sm);
	}
}

@include from($bp-medium) {
	.l-streamfield {
		padding: var(--padding-rythm);
		.section-title {
			padding-left: 0;
			padding-right: 0;
			margin-left: 0;
			margin-right: 0;
		}

		&__constrained {
			width: 70%;
		}
	}
}

@include from($bp-large) {
	.l-streamfield {
		&__constrained {
			width: 900px;
		}
	}
}
