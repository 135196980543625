@font-face {
	font-family: "poynter-oldstyle-display";
	src: url("../fonts/poynter-oldstyle-display-italic-400.woff2") format("woff2"),
		url("../fonts/poynter-oldstyle-display-italic-400.woff") format("woff"),
		url("../fonts/poynter-oldstyle-display-italic-400.ttc") format("opentype");
	font-display: swap;
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: "poynter-oldstyle-display";
	src: url("../fonts/poynter-oldstyle-display-normal-400.woff2") format("woff2"),
		url("../fonts/poynter-oldstyle-display-normal-400.woff") format("woff"),
		url("../fonts/poynter-oldstyle-display-normal-400.ttc") format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "poynter-oldstyle-display";
	src: url("../fonts/poynter-oldstyle-display-normal-600.woff2") format("woff2"),
		url("../fonts/poynter-oldstyle-display-normal-600.woff") format("woff"),
		url("../fonts/poynter-oldstyle-display-normal-600.ttc") format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: "poynter-oldstyle-display";
	src: url("../fonts/poynter-oldstyle-display-italic-600.woff2") format("woff2"),
		url("../fonts/poynter-oldstyle-display-italic-600.woff") format("woff"),
		url("../fonts/poynter-oldstyle-display-italic-600.ttc") format("opentype");
	font-display: swap;
	font-style: italic;
	font-weight: 600;
}
