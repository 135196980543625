.l-banner {
	position: relative;

	border-radius: $border-radius;
	overflow: hidden;

	&--cover {
		position: relative;
		z-index: 1;

		color: white;

		padding: var(--padding-rythm-sm);
	}

	&--title {
		font-size: var(--fs-h1);
		margin-bottom: var(--padding-rythm);
	}

	&--form .form-field {
		margin-bottom: calc(var(--padding-rythm) / 2);
	}
	&--form .form-field {
		margin-top: calc(var(--padding-rythm) / 2);
	}
	&--tagline {
		margin-bottom: var(--padding-rythm);
	}

	&--bg-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: darken(#a13555, 10%);
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__dark &--cover {
		color: $gold;
	}
}

@include to($bp-medium) {
	.l-banner {
		&--cover {
			display: flex;
			flex-direction: column-reverse;
		}
		&--copy {
			margin-top: var(--padding-rythm);
		}
		&--book-image {
			width: 45%;
		}
	}
}

@include from($bp-medium) {
	.l-banner {
		$c: &;
		&--cover {
			padding: calc(1.5 * var(--padding-rythm)) var(--padding-rythm);
			display: grid;
			grid-template-areas: "copy book";
			grid-template-columns: 6fr 2fr;
		}
		&--copy {
			grid-area: copy;
			padding-right: 35%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
		&--title {
			grid-area: title;
			margin-bottom: calc(var(--padding-rythm) / 2);

			&:last-child {
				margin-bottom: 0;
			}
		}
		&--tagline {
			margin-bottom: calc(var(--padding-rythm) / 2);
		}

		&--book-image {
			grid-area: book;
		}

		&__preserve-aspect {
			#{$c}--bg-image {
				position: relative;
			}
			#{$c}--cover {
				z-index: 1;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
}
