.l-book-listing {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: var(--padding-rythm);
	&--single {
		transition: transform 200ms ease-in-out;
		&:hover {
			transform: translateY(-4px);
		}
		img {
			display: block;
			box-shadow: $box-shadow;
		}
	}
}

@include from($bp-medium) {
	.l-book-listing {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}
