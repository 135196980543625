.btn {
	transition: 200ms ease-in-out;
	height: 40px;
	padding: 0 25px;
	text-align: center;
	font-size: var(--fs-body-lg);
	border-radius: 20px;
	border: 1px solid white;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: none;
	color: white;

	&__dark {
		border-color: $gold;
		color: $gold;
	}

	&:hover,
	&:active,
	&:focus {
		background: white;
		color: $gold;
	}
}

@include from($bp-medium) {
	.btn {
		min-width: 15vw;
		height: var(--padding-rythm);
		border-radius: calc(var(--padding-rythm) / 2);
		line-height: 1;
	}
	.btn-group {
		.btn + .btn {
			margin-top: 0;
			margin-left: calc(var(--padding-rythm) / 2);
		}
	}
}
