/**
 * Media Queries - *Try* and make everything fit within these - use @from/to/between
 */
$bp-small: 768;
$bp-medium: 1024;
$bp-large: 1280;
$bp-very-large: 1400;

/**
 * Font definitions
 */
$defaultFontSize: 1;

$body-font: "poynter-oldstyle-display", sans-serif;
$heading-font: "poynter-oldstyle-display", sans-serif;

/**
 * Transitions
 */
$base-anim-rythm: 300ms;
$transition-default: all $base-anim-rythm ease-in-out;

/**
 * Raw Colors
 */
$dark-grey: #c5bdbd;
$middle-grey: #808285; // #a7a9ac - put the similar colors you've replaced here
$light-grey: #efeff0; // #eaebec

$light-orange: #feeadd;
$gold: #a37f43;
$dark-gold: darken($gold, 20%);

/**
 * z-index stack - where possible use DOM order or small hoists (eg z-index: 1 vs z-index: $zi-layer-4)
 */
$zi-layer-1: 20;
$zi-layer-2: 40;
$zi-layer-3: 60;
$zi-layer-4: 80;
$zi-layer-nav: 200;

/**
 * Misc
 */
$max-width: ($bp-medium * 1px);
$box-shadow: 0 0 40px rgba(black, 0.1);
$mobile-header-height: 78px;

$heading-x-offset: -0.1em;

$border-radius: 8px;

:root {
	--fs-h1: 40px;
	--fs-h2: 30px;
	--fs-h3: 24px;
	--fs-tiny: 16px;
	--fs-body-lg: 18px;

	--padding-rythm-lg: 7.6vw;
	--padding-rythm: 4.4vw;
	--padding-rythm-sm: 30px;
}

@include from($bp-medium) {
	:root {
		--fs-h1: calc(1px + 4vw);
		--fs-h2: calc(1px + 3.22vw);
		--fs-h3: calc(1px + 1.95vw);
		--fs-tiny: calc(1px + 1.1vw);
		--fs-body-lg: calc(1px + 1.66vw);

		--padding-rythm-lg: 7.6vw;
		--padding-rythm: 4.4vw;
		--padding-rythm-sm: 30px;
	}
}
