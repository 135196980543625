.l-book-header {
	display: flex;
	padding: var(--padding-rythm-sm);
	&--book-image {
		margin-right: var(--padding-rythm);
	}
	&--title {
		font-size: var(--fs-h1);
		margin-bottom: var(--padding-rythm);
	}
	&--copy {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
}

@include from($bp-medium) {
	.l-book-header {
		padding: var(--padding-rythm);
		&--title {
			margin-bottom: calc(var(--padding-rythm) / 2);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
